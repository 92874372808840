import { template as template_6ce8a2157d55405ea66f3201db8f32d1 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_6ce8a2157d55405ea66f3201db8f32d1(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
