import { template as template_53b8b5b3ea4440a9b616b1b71e1a751b } from "@ember/template-compiler";
const WelcomeHeader = template_53b8b5b3ea4440a9b616b1b71e1a751b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
