import { template as template_633752cfabdc4282a2f841642b97d390 } from "@ember/template-compiler";
const FKLabel = template_633752cfabdc4282a2f841642b97d390(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
